import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0zm0 21.6A9.597 9.597 0 012.4 12c0-5.305 4.296-9.6 9.6-9.6 5.304 0 9.6 4.295 9.6 9.6 0 5.303-4.296 9.6-9.6 9.6zm6-9.6a6 6 0 11-12 0 6 6 0 0112 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Icon
