import React, { useEffect } from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql, navigate } from 'gatsby'

import SEO from '../components/seo'
import Footer from '../components/footer'
import HeaderText from '../components/headerText'
import SubText from '../components/subText'
import Box from '../components/box'
import Typography from '../components/typography'
import Button from '../components/button'
import Collapsible from '../components/collapsible'
import HeartbeatSignupForm from '../components/heartbeatSignupForm'
import Nav from '../components/nav'
import checkUtmParams from '../utils/checkUtmParams'

import Slack from '../icons/slack'
import Pulse from '../icons/pulse'
import Check from '../icons/checkCircle'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const SlackVsPulse = () => {
  const data = useStaticQuery(graphql`
    query {
      stripedBackground: file(relativePath: { eq: "striped-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  return (
    <>
      <SEO
        title="Slack vs. Pulse - When to use one versus the other"
        description="See how you exactly leverage the perfect combination of Pulse & Slack to thrive in a remote work environment."
      />
      <Nav />
      <Box
        marginTop="5.625rem"
        marginBottom="1.875rem"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <HeaderText as="h1">Pulse vs Slack. Go.</HeaderText>
        <SubText as="p" style={{ fontWeight: 400 }}>
          Using Slack? See how the two stack up and why should get on Pulse.
        </SubText>
      </Box>
      <Comparison>
        <SlackFeatures>
          <Box
            padding="2.5rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              textType="heading-large"
              fontWeight="bold"
              component="h2"
              style={{
                margin: '0px',
                textTransform: 'uppercase',
                fontStyle: 'italic',
              }}
            >
              Slack
            </Typography>
            <Slack />
          </Box>
          <Box
            padding="1.5rem"
            backgroundColor="#E2E2E2"
            display="flex"
            flexDirection="column"
            boxSizing="border-box"
          >
            <Typography component="p">
              Designed like a chat, for staying connected through the day.
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            padding="1.5rem"
            height="100%"
          >
            <Box display="flex" flexDirection="column" flex={1}>
              <Feature as="li">Real-time conversations</Feature>
              <Feature as="li">Intra-team comms</Feature>
              <Feature as="li">Quick direction questions</Feature>
              <Feature as="li">Fun/Cultural matters</Feature>
              <Feature as="li">Productivity Bots</Feature>
            </Box>
          </Box>
        </SlackFeatures>
        <PulseFeatures>
          <Box
            padding="2.5rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              textType="heading-large"
              fontWeight="bold"
              style={{
                margin: '0px',
                textTransform: 'uppercase',
                fontStyle: 'italic',
              }}
              component="h2"
            >
              Pulse
            </Typography>
            <Pulse />
          </Box>
          <Box display="flex" height="100%" className="advanced-comparison">
            <Box flex={1} display="flex" flexDirection="column" height="100%">
              <Box
                padding="1.5rem"
                backgroundColor="black"
                boxSizing="border-box"
              >
                <Typography
                  colorType="core.content.inversePrimary"
                  component="p"
                >
                  Engineered like a Mission Control Center, <br /> for staying
                  asynchronously aligned on what matters.
                </Typography>
              </Box>
              <Box display="flex">
                <Box
                  display="flex"
                  flexDirection="column"
                  padding="1.5rem"
                  alignItems="space-between"
                  height="100%"
                >
                  <Box display="flex" flexDirection="column" flex={1.25}>
                    <Feature as="li">Leadership comms</Feature>
                    <Feature as="li">Inter-teams comms</Feature>
                    <Feature as="li">Mission Critical information</Feature>
                    <Feature as="li">Async discussions</Feature>
                    <Feature as="li">Long-form updates</Feature>
                    <Feature as="li">Decisions</Feature>
                    <Feature as="li">Long-term endeavors</Feature>
                    <Feature as="li">Strategy</Feature>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  padding="1.5rem"
                  alignItems="space-between"
                  height="100%"
                >
                  <Box display="flex" flexDirection="column" flex={1.25}>
                    <Feature as="li">Global announcements</Feature>
                    <Feature as="li">Internal Memos</Feature>
                    <Feature as="li">Team announcements</Feature>
                    <Feature as="li">Stakeholders comms</Feature>
                    <Feature as="li">Cross-functional project comms</Feature>
                    <Feature as="li"> Daily or weekly check-ins</Feature>
                    <Feature as="li">Long-term reflections</Feature>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </PulseFeatures>
      </Comparison>

      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <HeaderText as="h3">FAQ</HeaderText>
        <Box width="56.25rem" margin="auto" marginBottom="10rem" maxWidth="95%">
          <Collapsible
            contents={[
              {
                header: 'How can I upgrade to a Advanced Team Plan?',
                preview:
                  'You can change your plan at any time, whether that’s an upgrade or a downgrade..',
                content: (
                  <>
                    You can change your plan at any time, whether that’s an
                    upgrade or a downgrade. Your changes will be prorated and
                    reflected on your following month’s invoice. To change your
                    plan, send us an email at{' '}
                    <a
                      href="mailto:support@pulse.so"
                      style={{ color: 'black' }}
                    >
                      support@pulse.so
                    </a>{' '}
                    and we'll take care of it for you. And if you’re on our
                    Enterprise, you can find details around switching your plan
                    in your contract. Reach out to your customer success manager
                    if you have any questions.
                  </>
                ),
              },
              {
                header: 'Can you send me an invoice?',
                preview:
                  "If you're on the Advanced Plan for Teams you can reach out at..",
                content: (
                  <>
                    If you're on the Advanced Plan for Teams you can reach out
                    at{' '}
                    <a
                      href="mailto:support@pulse.so"
                      style={{ color: 'black' }}
                    >
                      support@pulse.so
                    </a>{' '}
                    with additional details. If you're on the Advanced Plan for
                    Enterprises, reach out to your Customer Success Manager.
                  </>
                ),
              },
              {
                header: 'How do I cancel my paid plan?',
                preview:
                  'On our Advanced Plan for Team, you’ll be billed monthly and can cancel at any time..',
                content: (
                  <>
                    On our Advanced Plan for Team, you’ll be billed monthly and
                    can cancel at any time. There’s no long-term agreement, and
                    you can delete your Pulse account at any time. While we're
                    working to automate this, for the time being you can
                    contactat{' '}
                    <a
                      href="mailto:support@pulse.so"
                      style={{ color: 'black' }}
                    >
                      support@pulse.so
                    </a>{' '}
                    for termination and renewal details. If you're and reach out
                    to your account rep for more info. On our Advanced Plan for
                    Enterprises you can reach out directly to your Customer
                    Success Manager.
                  </>
                ),
              },
              {
                header: 'Refund Policy',
                preview:
                  'If you’re not 100% satisfied with the purchase, or it’s not what you were expecting..',
                content: (
                  <>
                    {' '}
                    If you’re not 100% satisfied with the purchase, or it’s not
                    what you were expecting, just reach out at{' '}
                    <a
                      href="mailto:support@pulse.so"
                      style={{ color: 'black' }}
                    >
                      support@pulse.so
                    </a>{' '}
                    within 30 days, and you’ll get a full refund. No questions
                    asked.
                  </>
                ),
              },
            ]}
          />
        </Box>
      </Box>

      <Box marginTop="8.75rem" marginBottom="8.125rem">
        <BackgroundImage
          fluid={data.stripedBackground.childImageSharp.fluid}
          backgroundColor="black"
          style={{
            height: '25rem',
            display: 'flex',
          }}
        >
          <Box
            margin="auto"
            width="31.25rem"
            maxWidth="97.5%"
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <HeaderText style={{ color: '#ffffff' }} as="h3">
              Learn Why Pulse
            </HeaderText>
            <SubText style={{ color: '#ffffff' }} as="p">
              Learn why in the age of abundance of information, your ability to
              create signals from the noise by quickly zeroing in on the most
              valuable and relevant content creates a competitive edge.
            </SubText>
            <Button
              onClick={() => navigate('/why')}
              style={{
                backgroundColor: '#eeeeee',
                color: '#000000',
              }}
            >
              Why Pulse
            </Button>
          </Box>
        </BackgroundImage>
      </Box>
      <HeartbeatSignupForm />
      <Footer />
    </>
  )
}

const Feature = ({ children, as }) => {
  return (
    <Typography
      textType="paragraph-medium"
      component={as}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1.875rem',
      }}
    >
      <Check />
      <Box marginRight=".625rem" />
      {children}
    </Typography>
  )
}

const Comparison = styled.div`
  display: flex;
  width: 67.75rem;
  max-width: 97.5%;
  margin: auto;
  margin-bottom: 9.375rem;
  flex-direction: column;
  height: auto;

  @media (min-width: 1059px) {
    flex-direction: row;
    height: 42.5rem;
  }
`

const SlackFeatures = styled.div`
  background-color: ${props => props.theme.core.background.secondary};
  height: 100%;
  flex: 1;
  margin-right: 0.625rem;
  display: flex;
  flex-direction: column;
`

const PulseFeatures = styled.div`
  background-color: ${props => props.theme.core.background.secondary};
  flex: 2.5;
  display: flex;
  flex-direction: column;
  height: 100%;

  .advanced-comparison {
    flex-direction: column;
  }

  @media (min-width: 1059px) {
    .advanced-comparison {
      flex-direction: row;
    }
  }
`

export default SlackVsPulse
