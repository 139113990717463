import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000"
        d="M5.042 15.166a2.527 2.527 0 01-2.52 2.522A2.527 2.527 0 010 15.167a2.527 2.527 0 012.521-2.522h2.521v2.521zM6.313 15.166a2.527 2.527 0 012.52-2.521 2.527 2.527 0 012.522 2.521v6.313A2.527 2.527 0 018.834 24a2.527 2.527 0 01-2.521-2.521v-6.313zM8.834 5.042a2.527 2.527 0 01-2.521-2.52A2.527 2.527 0 018.832 0a2.527 2.527 0 012.522 2.521v2.521H8.834zM8.834 6.313a2.527 2.527 0 012.52 2.52 2.527 2.527 0 01-2.52 2.522H2.52A2.527 2.527 0 010 8.834a2.527 2.527 0 012.521-2.521h6.313zM18.957 8.834a2.527 2.527 0 012.521-2.521A2.527 2.527 0 0124 8.832a2.527 2.527 0 01-2.52 2.522h-2.522V8.834zM17.687 8.834a2.527 2.527 0 01-2.521 2.521 2.527 2.527 0 01-2.521-2.521V2.52A2.527 2.527 0 0115.165 0a2.527 2.527 0 012.522 2.521v6.313zM15.165 18.958a2.527 2.527 0 012.52 2.52A2.527 2.527 0 0115.166 24a2.527 2.527 0 01-2.521-2.521v-2.521h2.52zM15.166 17.687a2.527 2.527 0 01-2.521-2.52 2.527 2.527 0 012.52-2.522h6.313A2.527 2.527 0 0124 15.166a2.527 2.527 0 01-2.52 2.521h-6.313z"
      ></path>
    </svg>
  )
}

export default Icon
