import React from 'react'
import styled from 'styled-components'
import Box from './box'
import Input from './input'
import Button from './button'

const Header = styled.h3`
  font-style: italic;
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 2.75rem;
  text-align: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
`

const SubText = styled.p`
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
  color: ${props => props.theme.core.content.tertiary};
  margin-bottom: 1.875rem;
`

const HeartbeatSignupForm = ({ headerText }) => (
  <Box
    width="43.75rem"
    margin="auto"
    display="flex"
    flexDirection="column"
    alignItems="center"
    marginBottom="11.25rem"
    maxWidth="97%"
  >
    <Header>
      {headerText ||
        `the heartbeat of
high-performing organizations.`}
    </Header>
    <SubText>
      Pulse helps you and your team to get more done, stay aligned more
      effectively, and accomplish what was thought impossible.
    </SubText>
    <form
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
      action="/signup/"
      method="get"
      className="validate"
      noValidate
    >
      <Input
        placeholder="Your work email"
        type="email"
        name="email"
        className="required email"
        style={{ marginBottom: '.5rem', marginRight: '0.5rem' }}
      />
      <Button
        type="submit"
        style={{
          backgroundColor: 'black',
          color: '#ffffff',
          marginBottom: '.5rem',
          maxWidth: '80%',
        }}
      >
        Get Pulse
      </Button>
    </form>
  </Box>
)

export default HeartbeatSignupForm
